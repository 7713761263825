import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    PageList,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../components/InnerPage';

const TemplatePage = () => (
    <InnerPage title="Title here" description="">
        <IntroHolder
            title="Title here"
            breadcrumbs={{
                grandparent: 'Grandparent page title here',
                parent: 'Parent page title here',
                'current-item': 'Current page title here',
            }}
        >
            <IntroQuote>Quote here</IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                </p>
                <div>
                    <p>
                        Augue ut lectus arcu bibendum. Amet purus gravida quis
                        blandit turpis. Egestas egestas fringilla phasellus
                        faucibus scelerisque eleifend donec pretium vulputate.
                        Amet purus gravida quis blandit turpis. Leo a diam
                        sollicitudin tempor. Vestibulum sed arcu non odio
                        euismod. Metus dictum at tempor commodo. Volutpat est
                        velit egestas dui id. Quam lacus suspendisse faucibus
                        interdum posuere lorem. Vitae nunc sed velit dignissim
                        sodales ut eu sem. Aliquam vestibulum morbi blandit
                        cursus risus at ultrices. Vel facilisis volutpat est
                        velit egestas dui. Bibendum neque egestas congue quisque
                        egestas diam in arcu cursus. Blandit libero volutpat sed
                        cras ornare. Amet consectetur adipiscing elit
                        pellentesque habitant morbi tristique. Dolor sit amet
                        consectetur adipiscing elit pellentesque habitant morbi
                        tristique. Massa tincidunt nunc pulvinar sapien et
                        ligula ullamcorper malesuada proin.
                    </p>
                </div>
                <PageList />
                <Reference>
                    <p>1. Reference</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default TemplatePage;
